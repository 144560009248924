import bunzz from "bunzz-sdk";
import { useEffect, useState } from "react";
import { Contract } from "bunzz-sdk";

const DAPP_ID = "16e83d5d-ad4e-4c9c-bf41-4055517ab9e1";
const API_KEY = "95dd765d-bbed-48c3-8999-4fe62070fbbd";
function Owner() {
  
  const [contract, setContract] = useState<Contract>();
  const [userAddress, setUserAddress] = useState("");
  const init = async () => {
    // Initialize SDK
    const handler = await bunzz.initWithConnect({
      dappId: DAPP_ID,
      apiKey: API_KEY,
    });
  const contract = await handler.getContract("Token (ERC20)");
  const userAddress = await handler.getSignerAddress();
    // Set local state
    setContract(contract);
    setUserAddress(userAddress);
  };
  // Run init() when rendering
  useEffect(() => {
    init();
  }, []);
//   const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
//     setValue(Number(event.target.value));
//   };

//   const handleCoinChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
//     setCoinValue(Number(event.target.value));
//   };
  // minting coins 
  const submit = async () => {
    if (!contract) return;
    const value = prompt("Insert amount coins to mint")
    await contract.mint(userAddress, value);
    alert("Transaction was sent in success🎉");
  };
  // deleting coins 
  const burnCoins = async () => {
    if (!contract) return;
    const value = prompt("Insert amount coins to burn")
    await contract.burn(value);
    const coins = await contract.totalSupply();
    console.log(coins.data);
  }; 
  const pauseCoins = async () => {
     if(!contract) return;
     await contract.pause();
  }
  const unpauseCoins = async () => {
    if(!contract) return;
    await contract.unpause();
 }
    return(
        <div className="container">
            <div className="container-title">
                <h3>Admin Dashboard</h3>
            </div>
            <div className="container-form">
                <button onClick={submit}>Mint</button>
                <button onClick={burnCoins}>Burn</button>
                <button onClick={pauseCoins}>Pause</button>
                <button onClick={unpauseCoins}>Unpause</button>
            </div>
        </div>
    )
}
export default Owner;