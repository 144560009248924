import "./App.css";
import {BrowserRouter, Link, Route, Routes} from 'react-router-dom'
import User from "../user/User";
import Owner from "../owner/Owner";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <div className="nav">
        <h1>
          <Link to="/" className="owner-href">
            Owner
          </Link>
        </h1>
        <h1>
          <Link to="/user">
            User
          </Link>
        </h1>
      </div>
        <Routes>
          <Route path="/" element={<Owner></Owner>}></Route>
          <Route path="/user" element={<User></User>}></Route>
        </Routes>
      </BrowserRouter>  
      <div className="footer">
          <p>Copyright@ Gameverse</p>
      </div>
    </div>
  );
}

export default App;