import bunzz from "bunzz-sdk";
import { useEffect, useState } from "react";
import { Contract } from "bunzz-sdk";
const DAPP_ID = "16e83d5d-ad4e-4c9c-bf41-4055517ab9e1";
const API_KEY = "95dd765d-bbed-48c3-8999-4fe62070fbbd";
function User() {
    const [contract, setContract] = useState<Contract>();
    const [userAddress, setUserAddress] = useState("");
    const init = async () => {
    // Initialize SDK
        const handler = await bunzz.initWithConnect({
          dappId: DAPP_ID,
          apiKey: API_KEY,
        });
    const contract = await handler.getContract("Token (ERC20)");
    const userAddress = await handler.getSignerAddress();
        // Set local state
        setContract(contract);
        setUserAddress(userAddress);
      };
    // Run init() when rendering
    useEffect(() => {
        init();
      }, []);
    // 	returns the amount of coins in existence
    const existingCoins = async () => {
        if (!contract) return;
        await contract.totalSupply().then((successMessage)=> {
            window.alert("Coins in existence " + successMessage.data)
        });
    }
    // returns the coin amount of certain user adres
    const userBalance =async () => {
        if (!contract) return;
        const adres = prompt("Insert addres to check balance")
        await contract.balanceOf(adres).then((successMessage) => {
            window.alert("Balance of given addres " + successMessage.data + " tokens");
          });
    }
    // transfers defined amount of coins to certain adres 
    const transferCoins =async () => {
        if (!contract) return;
        const adres = prompt("Insert adres to transfer coins")
        const value = prompt("Insert amout of coins to transfer")
        await contract.transfer(adres, value);
    }
    return(
        <div className="container">
            <div className="container-title">
            <h3>User Dashboard</h3>
            </div>
            <div className="container-form">
                <button onClick={existingCoins}>Existing Coins</button>
                <button onClick={userBalance}>User Balance</button>
                <button onClick={transferCoins}>Transfer Coins</button>
            </div>
        </div>
    )
}
export default User;